import { API, Auth } from 'aws-amplify'
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from "react-hot-toast";
import config from "../config.json";
import { DatePicker } from "./DatePicker";
import './datepicker.css';
import OverlayLoading from './OverlayLoading.js'

export default function NewsList(props) {
  const navigate = useNavigate();
  return (
    <NewsListComp {...props} navigate={ navigate } />
  );
}

class NewsListComp extends React.Component {
  constructor(props, params) {
    super(props);
    this.fileInput = React.createRef();
    this.state = {
      api_name: config.stage,
      loading: false,
      news: [],
      title: '',
      content: '',
      publish_date_start: '',
      publish_date_end: '',
    };

  }

  componentDidMount() {
    this.getNewsList();
  }

  getToken = async () => {
    return `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
  };

  getDateString = (d) => {
    return d ? d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() : '';
  };

  getNewsList = async () => {
    this.setState({ loading: true });

    const myinit = {
      headers: {
        Authorization: await this.getToken(),
      },
      queryStringParameters: {
        title: this.state.title,
        content: this.state.content,
        publish_date_start: this.getDateString(this.state.publish_date_start),
        publish_date_end: this.getDateString(this.state.publish_date_end),
      },
    };

    await API.get(this.state.api_name, "/news", myinit).then((r) => {
      this.setState({
        news: r,
      });
    }).catch((e) => {
      toast.error("お知らせデータの取得エラー");
    });

    this.setState({ loading: false });
  };

  render() {
    const news_html = [];
    const n = this.state.news;
    for (let i = 0; i < n.length; i++) {
      news_html.push(
        <tr key={i}>
          <td><button type="button" className="btn btn-sm btn-primary"
              onClick={ () => { this.props.navigate("/news_edit/" + n[i].id); } }>編集</button>
          </td>
          <td>{ n[i].title }</td>
          <td style={{ whiteSpace: "pre-wrap" }}>{ n[i].content }</td>
          <td>{ n[i].publish_date_start } 〜 { n[i].publish_date_end }</td>
        </tr>
      );
    }


    return (
      <div className="content-wrapper">
        <OverlayLoading show={ this.state.loading } />
        {/* Content Wrapper. Contains page content */}
        <Toaster />
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="mb-0">お知らせ管理</h3>
              </div>
            </div>
          </div>{/* /.container-fluid */}
        </section>

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="card-body pt-2 pb-2">
                <div className="row">
                  <div className="col-lg-2" style={{ maxWidth: '250px' }}>
                    <label className="small">タイトル</label>
                    <div className="form-group mb-0">
                      <input type="text"
                        className="form-control"
                        id="title"
                        placeholder="※部分一致"
                        value={ this.state.title }
                        onChange={ (event) => {
                          this.setState({ title: event.target.value })
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-2" style={{ maxWidth: '250px' }}>
                    <label className="small">本文</label>
                    <div className="form-group mb-0">
                      <input type="text"
                        className="form-control"
                        id="content"
                        placeholder="※部分一致"
                        value={ this.state.content }
                        onChange={ (event) => {
                          this.setState({ content: event.target.value })
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-2" style={{ maxWidth: '160px' }}>
                    <label className="small">公開日</label>
                    <div className="form-group mb-0">
                      <div className="input-group date publish_date" id="publish_date_start" data-target-input="nearest">
                        <div className="input-group-append" data-target="#publish_date_start" data-toggle="datetimepicker">
                          <DatePicker
                            className="form-control"
                            selected={this.state.publish_date_start}
                            onChange={ (event) => { this.setState({ publish_date_start: event }) }}
                          />
                          <div className="input-group-text"><i className="fa fa-calendar"></i></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1" style={{ maxWidth: '15px' }}>
                    <label className="small">　</label>
                    <div className="form-group mb-0">
                      <div className="form-text">-</div>
                    </div>
                  </div>
                  <div className="col-lg-2" style={{ maxWidth: '160px' }}>
                    <label className="small">　</label>
                    <div className="form-group mb-0">
                      <div className="input-group date publish_date" id="publish_date_end" data-target-input="nearest">
                        <div className="input-group-append" data-target="#publish_date_start" data-toggle="datetimepicker">
                          <DatePicker
                            className="form-control"
                            selected={ this.state.publish_date_end }
                            onChange={ (event) => { this.setState({ publish_date_end: event }) }}
                          />
                          <div className="input-group-text"><i className="fa fa-calendar"></i></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer pt-2 pb-2">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group clearfix mb-0">
                      <button 
                        type="button" 
                        className="btn btn-success"
                        onClick={ this.getNewsList }
                      >
                        検索
                      </button>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group clearfix mb-0 text-right">
                      <button type="button" className="btn btn-primary" onClick={ () => { this.props.navigate('/news_edit/new'); } }>新規登録</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card card-primary card-outline">
              <div className="card-body table-responsive">
                <table id="news_datatable" className="table table-sm table-bordered table-hover table-head-fixed text-nowrap">
                  <thead>
                    <tr>
                      <th>編集</th>
                      <th>タイトル</th>
                      <th>本文</th>
                      <th>公開日</th>
                    </tr>
                  </thead>
                  <tbody>
                    { news_html }
                  </tbody>
                </table>
              </div>
            </div>
          </div>{/* /.container-fluid */}
        </section>
        {/* /.content */}
      {/* /.content-wrapper */}
      </div>
    );
  }
}

