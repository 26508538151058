import { API, Auth } from "aws-amplify";
import React from "react";
import { Button } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Constant from "../Constant.js";
import { DatePicker } from "../components/DatePicker";
import Modal from "../components/Modal";
import config from "../config.json";

export default function Reservation(props) {
  const navigate = useNavigate();
  return <ReservationComp {...props} navigate={navigate} />;
}
class ReservationComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_name: config.stage,
      reservation_date: new Date(),
      site_id: 1,
      car_type: 1,
      all_f: 1,
      light_f: 1,
      request: {},
      reservations: [],
      site: {},
      create_reservation: {
        reservation_date: "",
        site_id: "",
        site_name: "",
        car_type: "",
        car_type_name: "",
        round: "",
        round_name: "",
        inspection_type: "",
        inspection_type_name: "",
        reservation_no: "",
        auth_no: "",
        body_no: "",
        plate_no: "",
        remark: "",
      },
      update_reservation: {
        Reservation: {
          id: "",
          site_id: "",
          car_type: "",
          round: "",
          inspection_type: "",
          reservation_date: "",
          reservation_no: "",
          member_id: "",
          body_no: "",
          plate_no: "",
          remark: "",
        },
        member_name: null,
        auth_no: null,
        site_name: null,
        car_type_name: null,
        round_name: null,
        inspection_type_name: null,
        reception_type_name: null,
      },
      create_modal_show: false,
      update_modal_show: false,
      mail_modal_show: false,
    };
    this.processing = React.createRef();

    this.handleReservationDate = this.handleReservationDate.bind(this);
    this.handleSiteId = this.handleSiteId.bind(this);
    this.handleCarType = this.handleCarType.bind(this);
    this.handleCreateAuthNo = this.handleCreateAuthNo.bind(this);
    this.handleCreateBodyNo = this.handleCreateBodyNo.bind(this);
    this.handleCreatePlateNo = this.handleCreatePlateNo.bind(this);
    this.handleCreateRemark = this.handleCreateRemark.bind(this);
    this.handleUpdateBoycott = this.handleUpdateBoycott.bind(this);
    this.handleUpdateRemark = this.handleUpdateRemark.bind(this);

  }

  componentDidMount() {
    this.getReservations();
  }

  getToken = async () => {
    return `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
  };

  getFormatDate(date) {
    return (
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );
  }

  getReservations = async () => {
    const request = {
      headers: {
        Authorization: await this.getToken(),
      },
      queryStringParameters: {
        reservation_date: this.getFormatDate(this.state.reservation_date),
        site_id: this.state.site_id,
        car_type: this.state.car_type,
        timestamp: new Date().getTime(),
      },
    };

    await API.get(this.state.api_name, "/reservations", request)
      .then((r) => {
        this.setState({
          request: request,
          reservations: r,
        });
      })
      .catch((e) => {
        toast.error("予約データの取得エラー");
      });
  };

  getCsv = async () => {
    const request = {
      headers: {
        Authorization: await this.getToken(),
      },
      queryStringParameters: {
        reservation_date: this.getFormatDate(this.state.reservation_date),
        site_id: this.state.site_id,
        car_type: this.state.car_type,
        timestamp: new Date().getTime(),
      },
    };

    await API.get(this.state.api_name, "/reservations/csv", request)
      .then((r) => {
        if (r) {
          const a = document.createElement("a");
          a.href = r;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      })
      .catch((e) => {
        toast.error("予約データのCSV出力エラー");
      });
  };

  getPdf = async () => {
    const request = {
      headers: {
        Authorization: await this.getToken(),
      },
      queryStringParameters: {
        reservation_date: this.getFormatDate(this.state.reservation_date),
        site_id: this.state.site_id,
        car_type: this.state.car_type,
        timestamp: new Date().getTime(),
      },
    };

    await API.get(this.state.api_name, "/reservations/pdf", request)
      .then((r) => {
        if (r) {
          const a = document.createElement("a");
          a.href = r;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      })
      .catch((e) => {
        toast.error("予約データのPDF出力エラー");
      });
  };

  sendMail = async () => {
    const request = {
      headers: {
        Authorization: await this.getToken(),
      },
      queryStringParameters: {
        reservation_date: this.getFormatDate(this.state.reservation_date),
        site_id: this.state.site_id,
        all_f: this.state.all_f,
        light_f: this.state.light_f,
        timestamp: new Date().getTime(),
      },
    };

    await API.get(this.state.api_name, "/reservations/send_mail_pdf", request)
      .then((r) => {
        // TODO
        //console.log(r);
        this.handleMailModalHide();
      })
      .catch((e) => {
        toast.error("予約データのメール配信エラー");
      });
  };

  readReservation = async () => {
    const request = {
      headers: {
        Authorization: await this.getToken(),
      },
      queryStringParameters: {
        timestamp: new Date().getTime(),
      },
    };

    await API.get(
      this.state.api_name,
      "/reservations/" + this.state.update_reservation.Reservation.id,
      request
    )
      .then((r) => {
        //console.log(r);
        r.Reservation.reservation_no = r.Reservation.reservation_no.padStart(
          2,
          "0"
        );
        this.setState({
          update_reservation: r,
        });
      })
      .catch((e) => {
        toast.error("予約データの取得エラー");
      });
  };

  createReservation = async () => {
    const request = {
      headers: {
        Authorization: await this.getToken(),
      },
      body: {
        auth_no: this.state.create_reservation.auth_no,
        site_id: this.state.create_reservation.site_id,
        car_type: this.state.create_reservation.car_type,
        inspection_type: this.state.create_reservation.inspection_type,
        reservation_date: this.state.create_reservation.reservation_date,
        round: this.state.create_reservation.round,
        reservation_no: this.state.create_reservation.reservation_no,
        body_no: this.state.create_reservation.body_no,
        plate_no: this.state.create_reservation.plate_no,
        remark: this.state.create_reservation.remark,
      },
    };

    await API.post(this.state.api_name, "/reservations", request)
      .then((r) => {
        //console.log(r);
        this.getReservations();
        this.handleCreateModalHide();
        toast.success("予約データを登録しました");
      })
      .catch((e) => {
        toast.error("予約データの登録エラー");
      });
  };

  updateReservation = async () => {
    const request = {
      headers: {
        Authorization: await this.getToken(),
      },
      body: {
        id: this.state.update_reservation.Reservation.id,
        body_no: this.state.update_reservation.Reservation.body_no,
        plate_no: this.state.update_reservation.Reservation.plate_no,
        boycott: this.state.update_reservation.Reservation.boycott,
        remark: this.state.update_reservation.Reservation.remark,
      },
    };

    await API.put(
      this.state.api_name,
      "/reservations/" + this.state.update_reservation.Reservation.id,
      request
    )
      .then((r) => {
        //console.log(r);
        this.getReservations();
        this.handleUpdateModalHide();
        toast.success("予約データを登録しました");
      })
      .catch((e) => {
        toast.error("予約データの登録エラー");
      });
  };

  deleteReservation = async () => {
    const request = {
      headers: {
        Authorization: await this.getToken(),
      },
      body: {
        id: this.state.update_reservation.Reservation.id,
      },
    };

    await API.del(
      this.state.api_name,
      "/reservations/" + this.state.update_reservation.Reservation.id,
      request
    )
      .then((r) => {
        //console.log(r);
        this.getReservations();
        this.handleUpdateModalHide();
        toast.error("予約データを削除しました");
      })
      .catch((e) => {
        toast.error("予約データの削除エラー");
      });
  };

  readSite = async () => {
    const request = {
      headers: {
        Authorization: await this.getToken(),
      },
      queryStringParameters: {
        timestamp: new Date().getTime(),
      },
    };

    await API.get(this.state.api_name, "/sites/" + this.state.site_id, request)
      .then((r) => {
        //console.log(r);
        this.setState({
          site: r,
        });
      })
      .catch((e) => {
        toast.error("検査場データの取得エラー");
      });
  };

  handleReservationDate = (date) => {
    this.setState({
      reservation_date: date,
    });
    this.getReservations();
  };

  handleSiteId = (event) => {
    this.setState({
      site_id: event.target.value,
    });
    this.getReservations();
  };

  handleCarType = (event) => {
    this.setState({
      car_type: event.target.value,
    });
    this.getReservations();
  };

  handleCreateAuthNo = (event) => {
    const create_reservation = this.state.create_reservation;
    create_reservation.auth_no = event.target.value;
    this.setState({
      create_reservation: create_reservation,
    });
  };

  handleCreateBodyNo = (event) => {
    const create_reservation = this.state.create_reservation;
    create_reservation.body_no = event.target.value;
    this.setState({
      create_reservation: create_reservation,
    });
  };

  handleCreatePlateNo = (event) => {
    const create_reservation = this.state.create_reservation;
    create_reservation.plate_no = event.target.value;
    this.setState({
      create_reservation: create_reservation,
    });
  };

  handleCreateRemark = (event) => {
    const create_reservation = this.state.create_reservation;
    create_reservation.remark = event.target.value;
    this.setState({
      create_reservation: create_reservation,
    });
  };

  handleUpdateBodyNo = (event) => {
    const update_reservation = this.state.update_reservation;
    update_reservation.Reservation.body_no = event.target.value;
    this.setState({
      update_reservation: update_reservation,
    });
  };

  handleUpdatePlateNo = (event) => {
    const update_reservation = this.state.update_reservation;
    update_reservation.Reservation.plate_no = event.target.value;
    this.setState({
      update_reservation: update_reservation,
    });
  };

  handleUpdateBoycott = (event) => {
    const update_reservation = this.state.update_reservation;
    update_reservation.Reservation.boycott = event.target.checked ? 1 : 0;
    this.setState({
      update_reservation: update_reservation,
    });
  };

  handleUpdateRemark = (event) => {
    const update_reservation = this.state.update_reservation;
    update_reservation.Reservation.remark = event.target.value;
    this.setState({
      update_reservation: update_reservation,
    });
  };

  handleMailAllF = (event) => {
    const all_f = event.target.checked ? 1 : 0;
    this.setState({
      all_f: all_f,
    });
  };

  handleMailLightF = (event) => {
    const light_f = event.target.checked ? 1 : 0;
    this.setState({
      light_f: light_f,
    });
  };

  handleCreateModalShow = (data) => {
    //console.log(this.state.create_reservation);
    const create_reservation = this.state.create_reservation;
    create_reservation.reservation_date = data.reservation_date;
    create_reservation.site_id = data.site_id;
    create_reservation.site_name = Constant.SITE_NAME_LIST[data.site_id]; // TODO
    create_reservation.car_type = data.car_type;
    create_reservation.car_type_name = Constant.CAR_TYPE_LIST[data.car_type];
    create_reservation.round = data.round;
    create_reservation.round_name = Constant.ROUND_LIST[data.round];
    create_reservation.inspection_type = data.inspection_type;
    create_reservation.inspection_type_name =
      Constant.INSPECTION_TYPE_LIST[data.inspection_type];
    create_reservation.reservation_no = data.reservation_no.padStart(2, "0");
    create_reservation.auth_no = "";
    create_reservation.body_no = "";
    create_reservation.plate_no = "";
    create_reservation.remark = "";
    this.setState({
      create_reservation: create_reservation,
    });
    this.setState({
      create_modal_show: true,
    });
    //console.log(data);
  };

  handleCreateModalHide = () => {
    this.setState({
      create_modal_show: false,
    });
  };

  handleUpdateModalShow = (data) => {
    const update_reservation = this.state.update_reservation;
    update_reservation.Reservation.id = data.id;
    this.setState({
      update_reservation: update_reservation,
    });
    this.readReservation();
    this.setState({
      update_modal_show: true,
    });
  };

  handleUpdateModalHide = () => {
    this.setState({
      update_modal_show: false,
    });
  };

  handleMailModalShow = () => {
    this.readSite();
    this.setState({
      mail_modal_show: true,
    });
  };

  handleMailModalHide = () => {
    this.setState({
      mail_modal_show: false,
    });
  };

  searchOnClick = async (event) => {
    this.getReservations();
  };

  csvOnClick = async (event) => {
    this.getCsv();
  };

  pdfOnClick = async (event) => {
    this.getPdf();
  };

  mailOnClick = async (event) => {
    this.sendMail();
  };

  createOnClick = async (event) => {
    if (!this.state.create_reservation.auth_no) {
      window.alert("認証番号を入力してください。");
      return false;
    }
    event.preventDefault();
    event.stopPropagation();
    if (this.processing.current) {
      return false;
    }
    this.processing.current = true;

    this.createReservation();

    setTimeout(() => {
      this.processing.current = false;
    }, 3000);
  };

  updateOnClick = async (event) => {
    //
    this.updateReservation();
  };

  deleteOnClick = async (event) => {
    if (!window.confirm("予約を削除します。よろしいですか？")) {
      return false;
    }
    this.deleteReservation();
  };

  render() {
    //console.log("render");
    //console.log(this.state.reservations);

    let reservation_list = {};
    let car_type_list = [1, 2];
    let round_list = [1, 2, 3, 4, 5];
    let inspection_type_list = [0, 1, 2, 3, 4];
    for (let i in car_type_list) {
      reservation_list[car_type_list[i]] = {};
      for (let j in round_list) {
        reservation_list[car_type_list[i]][round_list[j]] = {};
        for (let k in inspection_type_list) {
          reservation_list[car_type_list[i]][round_list[j]][
            inspection_type_list[k]
          ] = {};
        }
      }
    }
    //console.log(reservation_list);
    for (let i in this.state.reservations) {
      let r = this.state.reservations[i];
      if (!!r && Object.keys(r).length) {
        //console.log(r);
        //console.log(reservation_list);
        reservation_list[r.car_type][r.round][r.inspection_type][
          parseInt(r.reservation_no, 10)
        ] = r;
      }
    }
    //console.log(reservation_list);
    let car_type = 1;
    let site_id = 1;
    let reservation_date = "";
    //console.log(this.state.request);
    if (!!this.state.request.queryStringParameters) {
      //console.log(this.state.request.queryStringParameters);
      car_type = this.state.request.queryStringParameters.car_type;
      site_id = this.state.request.queryStringParameters.site_id;
      reservation_date =
        this.state.request.queryStringParameters.reservation_date;
    }

    //reservation[car_type][round][inspection_type][reservation_no]
    // site_idで分岐しない
    // 普通自動車／軽自動車
    // 1R
    // 2R-5R
    // 継続・構造変更・中古新規／新車新規／マルチュウ
    // 軽自動車

    let round_html_list = {};
    if (car_type == 1) {
      // 1R
      // 継続・構造変更・中古新規
      // 1列目：1-40
      let table_body_col_1 = [];
      for (let i = 1; i <= 40; i++) {
        let r = {};
        if (!!reservation_list[car_type][1][1][i]) {
          r = reservation_list[car_type][1][1][i];
        } else if (!!reservation_list[car_type][1][2][i]) {
          r = reservation_list[car_type][1][2][i];
        }
        let reservation_no = i.toString().padStart(2, "0");
        let key = "" + 1 + 1 + reservation_no;
        if (!!r && Object.keys(r).length) {
          //console.log(r);
          let auth_no = r.member_id.toString().padStart(4, "0");
          table_body_col_1.push(
            <tr key={key}>
              <td>{reservation_no}</td>
              <td className={r.boycott ? "bg-danger" : ""}>
                <a
                  href="#"
                  onClick={() => this.handleUpdateModalShow({ id: r.id })}
                >
                  {auth_no}
                </a>
              </td>
              <td>{r.body_no}</td>
              <td>{r.remark}</td>
            </tr>
          );
        } else {
          table_body_col_1.push(
            <tr key={key}>
              <td>{reservation_no}</td>
              <td>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() =>
                    this.handleCreateModalShow({
                      reservation_date: reservation_date,
                      site_id: site_id,
                      car_type: car_type,
                      round: 1,
                      inspection_type: 1,
                      reservation_no: reservation_no,
                    })
                  }
                >
                  空
                </button>
              </td>
              <td></td>
              <td></td>
            </tr>
          );
        }
      }
      // 2列目：41-80
      let table_body_col_2 = [];
      for (let i = 41; i <= 80; i++) {
        let r = {};
        if (!!reservation_list[car_type][1][1][i]) {
          r = reservation_list[car_type][1][1][i];
        } else if (!!reservation_list[car_type][1][2][i]) {
          r = reservation_list[car_type][1][2][i];
        }
        let reservation_no = i.toString().padStart(2, "0");
        let key = "" + 1 + 1 + reservation_no;
        if (!!r && Object.keys(r).length) {
          //console.log(r);
          let auth_no = r.member_id.toString().padStart(4, "0");
          table_body_col_2.push(
            <tr key={key}>
              <td>{reservation_no}</td>
              <td className={r.boycott ? "bg-danger" : ""}>
                <a
                  href="#"
                  onClick={() => this.handleUpdateModalShow({ id: r.id })}
                >
                  {auth_no}
                </a>
              </td>
              <td>{r.body_no}</td>
              <td>{r.remark}</td>
            </tr>
          );
        } else {
          table_body_col_2.push(
            <tr key={key}>
              <td>{reservation_no}</td>
              <td>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() =>
                    this.handleCreateModalShow({
                      reservation_date: reservation_date,
                      site_id: site_id,
                      car_type: car_type,
                      round: 1,
                      inspection_type: 1,
                      reservation_no: reservation_no,
                    })
                  }
                >
                  空
                </button>
              </td>
              <td></td>
              <td></td>
            </tr>
          );
        }
      }
      let inspection_1_2_html = (
        <div className="card card-success card-outline">
          <div className="card-header">
            <h6 style={{ lineHeight: "1.8" }}>継続・構造変更・中古新規</h6>
          </div>
          <div className="card-body table-responsive">
            <div className="row">
              <div className="col-lg-6">
                <table className="table table-sm table-bordered table-hover table-head-fixed text-nowrap table-reservation">
                  <thead>
                    <tr>
                      <th>番号</th>
                      <th>認証番号</th>
                      <th>車体番号</th>
                      <th>備考</th>
                    </tr>
                  </thead>
                  <tbody>{table_body_col_1}</tbody>
                </table>
              </div>
              <div className="col-lg-6">
                <table className="table table-sm table-bordered table-hover table-head-fixed text-nowrap table-reservation">
                  <thead>
                    <tr>
                      <th>番号</th>
                      <th>認証番号</th>
                      <th>車体番号</th>
                      <th>備考</th>
                    </tr>
                  </thead>
                  <tbody>{table_body_col_2}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
      // 新車新規
      // 3列目：1-40
      let table_body_col_3 = [];
      for (let i = 1; i <= 40; i++) {
        let r = {};
        if (!!reservation_list[car_type][1][3][i]) {
          r = reservation_list[car_type][1][3][i];
        }
        let reservation_no = i.toString().padStart(2, "0");
        let key = "" + 1 + 3 + reservation_no;
        if (!!r && Object.keys(r).length) {
          //console.log(r);
          let auth_no = r.member_id.toString().padStart(4, "0");
          table_body_col_3.push(
            <tr key={key}>
              <td>{reservation_no}</td>
              <td className={r.boycott ? "bg-danger" : ""}>
                <a
                  href="#"
                  onClick={() => this.handleUpdateModalShow({ id: r.id })}
                >
                  {auth_no}
                </a>
              </td>
              <td>{r.body_no}</td>
              <td>{r.remark}</td>
            </tr>
          );
        } else {
          table_body_col_3.push(
            <tr key={key}>
              <td>{reservation_no}</td>
              <td>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() =>
                    this.handleCreateModalShow({
                      reservation_date: reservation_date,
                      site_id: site_id,
                      car_type: car_type,
                      round: 1,
                      inspection_type: 3,
                      reservation_no: reservation_no,
                    })
                  }
                >
                  空
                </button>
              </td>
              <td></td>
              <td></td>
            </tr>
          );
        }
      }
      let inspection_3_html = (
        <div className="card card-success card-outline">
          <div className="card-header">
            <h6 style={{ lineHeight: "1.8" }}>新車新規</h6>
          </div>
          <div className="card-body table-responsive">
            <div className="row">
              <div className="col-lg-12">
                <table className="table table-sm table-bordered table-hover table-head-fixed text-nowrap table-reservation">
                  <thead>
                    <tr>
                      <th>番号</th>
                      <th>認証番号</th>
                      <th>車体番号</th>
                      <th>備考</th>
                    </tr>
                  </thead>
                  <tbody>{table_body_col_3}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
      // マルチュウ
      // 4列目：1-40
      let table_body_col_4 = [];
      for (let i = 1; i <= 40; i++) {
        let r = {};
        if (!!reservation_list[car_type][1][4][i]) {
          r = reservation_list[car_type][1][4][i];
        }
        let reservation_no = i.toString().padStart(2, "0");
        let key = "" + 1 + 4 + reservation_no;
        if (!!r && Object.keys(r).length) {
          let auth_no = r.member_id.toString().padStart(4, "0");
          table_body_col_4.push(
            <tr key={key}>
              <td>{reservation_no}</td>
              <td className={r.boycott ? "bg-danger" : ""}>
                <a
                  href="#"
                  onClick={() => this.handleUpdateModalShow({ id: r.id })}
                >
                  {auth_no}
                </a>
              </td>
              <td>{r.body_no}</td>
              <td>{r.remark}</td>
            </tr>
          );
        } else {
          table_body_col_4.push(
            <tr key={key}>
              <td>{reservation_no}</td>
              <td>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() =>
                    this.handleCreateModalShow({
                      reservation_date: reservation_date,
                      site_id: site_id,
                      car_type: car_type,
                      round: 1,
                      inspection_type: 4,
                      reservation_no: reservation_no,
                    })
                  }
                >
                  空
                </button>
              </td>
              <td></td>
              <td></td>
            </tr>
          );
        }
      }
      let inspection_4_html = (
        <div className="card card-success card-outline">
          <div className="card-header">
            <h6 style={{ lineHeight: "1.8" }}>マルチュウ</h6>
          </div>
          <div className="card-body table-responsive">
            <div className="row">
              <div className="col-lg-12">
                <table className="table table-sm table-bordered table-hover table-head-fixed text-nowrap table-reservation">
                  <thead>
                    <tr>
                      <th>番号</th>
                      <th>認証番号</th>
                      <th>車体番号</th>
                      <th>備考</th>
                    </tr>
                  </thead>
                  <tbody>{table_body_col_4}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
      // タブ
      round_html_list[1] = (
        <div
          className="card card-primary tab-pane fade active show"
          key="tab-round-1"
          id="tab-round-1"
          role="tabpanel"
          aria-labelledby="tab-round-1"
        >
          <div className="card-body table-responsive">
            <div className="row">
              <div className="col-lg-6">{inspection_1_2_html}</div>
              <div className="col-lg-3">{inspection_3_html}</div>
              <div className="col-lg-3">{inspection_4_html}</div>
            </div>
          </div>
        </div>
      );
      // R2-5
      for (let j = 2; j <= 5; j++) {
        // 継続・構造変更・中古新規
        // 1列目：1-40
        let table_body_col_1 = [];
        for (let i = 1; i <= 40; i++) {
          let r = {};
          if (!!reservation_list[car_type][j][1][i]) {
            r = reservation_list[car_type][j][1][i];
          } else if (!!reservation_list[car_type][j][2][i]) {
            r = reservation_list[car_type][j][2][i];
          }
          let reservation_no = i.toString().padStart(2, "0");
          let key = "" + j + 1 + reservation_no;
          if (!!r && Object.keys(r).length) {
            let auth_no = r.member_id.toString().padStart(4, "0");
            table_body_col_1.push(
              <tr key={key}>
                <td>{reservation_no}</td>
                <td className={r.boycott ? "bg-danger" : ""}>
                  <a
                    href="#"
                    onClick={() => this.handleUpdateModalShow({ id: r.id })}
                  >
                    {auth_no}
                  </a>
                </td>
                <td>{r.body_no}</td>
                <td>{r.remark}</td>
              </tr>
            );
          } else {
            table_body_col_1.push(
              <tr key={key}>
                <td>{reservation_no}</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={() =>
                      this.handleCreateModalShow({
                        reservation_date: reservation_date,
                        site_id: site_id,
                        car_type: car_type,
                        round: j,
                        inspection_type: 1,
                        reservation_no: reservation_no,
                      })
                    }
                  >
                    空
                  </button>
                </td>
                <td></td>
                <td></td>
              </tr>
            );
          }
        }
        // 2列目：41-80
        let table_body_col_2 = [];
        for (let i = 41; i <= 80; i++) {
          let r = {};
          if (!!reservation_list[car_type][j][1][i]) {
            r = reservation_list[car_type][j][1][i];
          } else if (!!reservation_list[car_type][j][2][i]) {
            r = reservation_list[car_type][j][2][i];
          }
          let reservation_no = i.toString().padStart(2, "0");
          let key = "" + j + 1 + reservation_no;
          if (!!r && Object.keys(r).length) {
            let auth_no = r.member_id.toString().padStart(4, "0");
            table_body_col_2.push(
              <tr key={key}>
                <td>{reservation_no}</td>
                <td className={r.boycott ? "bg-danger" : ""}>
                  <a
                    href="#"
                    onClick={() => this.handleUpdateModalShow({ id: r.id })}
                  >
                    {auth_no}
                  </a>
                </td>
                <td>{r.body_no}</td>
                <td>{r.remark}</td>
              </tr>
            );
          } else {
            table_body_col_2.push(
              <tr key={key}>
                <td>{reservation_no}</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={() =>
                      this.handleCreateModalShow({
                        reservation_date: reservation_date,
                        site_id: site_id,
                        car_type: car_type,
                        round: j,
                        inspection_type: 1,
                        reservation_no: reservation_no,
                      })
                    }
                  >
                    空
                  </button>
                </td>
                <td></td>
                <td></td>
              </tr>
            );
          }
        }
        let inspection_1_2_html = (
          <div className="card card-success card-outline">
            <div className="card-header">
              <h6 style={{ lineHeight: "1.8" }}>継続・構造変更・中古新規</h6>
            </div>
            <div className="card-body table-responsive">
              <div className="row">
                <div className="col-lg-6">
                  <table className="table table-sm table-bordered table-hover table-head-fixed text-nowrap table-reservation">
                    <thead>
                      <tr>
                        <th>番号</th>
                        <th>認証番号</th>
                        <th>車体番号</th>
                        <th>備考</th>
                      </tr>
                    </thead>
                    <tbody>{table_body_col_1}</tbody>
                  </table>
                </div>
                <div className="col-lg-6">
                  <table className="table table-sm table-bordered table-hover table-head-fixed text-nowrap table-reservation">
                    <thead>
                      <tr>
                        <th>番号</th>
                        <th>認証番号</th>
                        <th>車体番号</th>
                        <th>備考</th>
                      </tr>
                    </thead>
                    <tbody>{table_body_col_2}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        );
        // 新車新規
        // 1列目：1-40
        let table_body_col_3 = [];
        for (let i = 1; i <= 40; i++) {
          let r = {};
          if (!!reservation_list[car_type][j][3][i]) {
            r = reservation_list[car_type][j][3][i];
          }
          let reservation_no = i.toString().padStart(2, "0");
          let key = "" + j + 3 + reservation_no;
          if (!!r && Object.keys(r).length) {
            let auth_no = r.member_id.toString().padStart(4, "0");
            table_body_col_3.push(
              <tr key={key}>
                <td>{reservation_no}</td>
                <td className={r.boycott ? "bg-danger" : ""}>
                  <a
                    href="#"
                    onClick={() => this.handleUpdateModalShow({ id: r.id })}
                  >
                    {auth_no}
                  </a>
                </td>
                <td>{r.body_no}</td>
                <td>{r.remark}</td>
              </tr>
            );
          } else {
            table_body_col_3.push(
              <tr key={key}>
                <td>{reservation_no}</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={() =>
                      this.handleCreateModalShow({
                        reservation_date: reservation_date,
                        site_id: site_id,
                        car_type: car_type,
                        round: j,
                        inspection_type: 3,
                        reservation_no: reservation_no,
                      })
                    }
                  >
                    空
                  </button>
                </td>
                <td></td>
                <td></td>
              </tr>
            );
          }
        }
        let inspection_3_html = (
          <div className="card card-success card-outline">
            <div className="card-header">
              <h6 style={{ lineHeight: "1.8" }}>新車新規</h6>
            </div>
            <div className="card-body table-responsive">
              <div className="row">
                <div className="col-lg-12">
                  <table className="table table-sm table-bordered table-hover table-head-fixed text-nowrap table-reservation">
                    <thead>
                      <tr>
                        <th>番号</th>
                        <th>認証番号</th>
                        <th>車体番号</th>
                        <th>備考</th>
                      </tr>
                    </thead>
                    <tbody>{table_body_col_3}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        );
        // タブ
        let tab_key = "tab-round-" + j;
        round_html_list[j] = (
          <div
            className="card card-primary tab-pane fade"
            key={tab_key}
            id={tab_key}
            role="tabpanel"
            aria-labelledby={tab_key}
          >
            <div className="card-body table-responsive">
              <div className="row">
                <div className="col-lg-6">{inspection_1_2_html}</div>
                <div className="col-lg-3">{inspection_3_html}</div>
              </div>
            </div>
          </div>
        );
      }
    } else if (car_type == 2) {
      for (let j = 1; j <= 5; j++) {
        // 継続・構造変更・中古新規
        // 1列目：1-40
        let table_body_col_1 = [];
        for (let i = 1; i <= 40; i++) {
          let r = {};
          r = reservation_list[car_type][j][0][i];
          let reservation_no = i.toString().padStart(2, "0");
          let key = "" + j + 1 + reservation_no;
          if (!!r && Object.keys(r).length) {
            let auth_no = r.member_id.toString().padStart(4, "0");
            table_body_col_1.push(
              <tr key={key}>
                <td>{reservation_no}</td>
                <td className={r.boycott ? "bg-danger" : ""}>
                  <a
                    href="#"
                    onClick={() => this.handleUpdateModalShow({ id: r.id })}
                  >
                    {auth_no}
                  </a>
                </td>
                <td>{r.body_no}</td>
                <td>{r.remark}</td>
              </tr>
            );
          } else {
            table_body_col_1.push(
              <tr key={key}>
                <td>{reservation_no}</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={() =>
                      this.handleCreateModalShow({
                        reservation_date: reservation_date,
                        site_id: site_id,
                        car_type: car_type,
                        round: j,
                        inspection_type: 0,
                        reservation_no: reservation_no,
                      })
                    }
                  >
                    空
                  </button>
                </td>
                <td></td>
                <td></td>
              </tr>
            );
          }
        }
        // 2列目：41-80
        let table_body_col_2 = [];
        for (let i = 41; i <= 80; i++) {
          let r = {};
          r = reservation_list[car_type][j][0][i];
          let reservation_no = i.toString().padStart(2, "0");
          let key = "" + j + 1 + reservation_no;
          if (!!r && Object.keys(r).length) {
            let auth_no = r.member_id.toString().padStart(4, "0");
            table_body_col_2.push(
              <tr key={key}>
                <td>{reservation_no}</td>
                <td className={r.boycott ? "bg-danger" : ""}>
                  <a
                    href="#"
                    onClick={() => this.handleUpdateModalShow({ id: r.id })}
                  >
                    {auth_no}
                  </a>
                </td>
                <td>{r.body_no}</td>
                <td>{r.remark}</td>
              </tr>
            );
          } else {
            table_body_col_2.push(
              <tr key={key}>
                <td>{reservation_no}</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={() =>
                      this.handleCreateModalShow({
                        reservation_date: reservation_date,
                        site_id: site_id,
                        car_type: car_type,
                        round: j,
                        inspection_type: 0,
                        reservation_no: reservation_no,
                      })
                    }
                  >
                    空
                  </button>
                </td>
                <td></td>
                <td></td>
              </tr>
            );
          }
        }
        let inspection_1_2_html = (
          <div className="card card-success card-outline">
            <div className="card-header">
              <h6 style={{ lineHeight: "1.8" }}>軽自動車</h6>
            </div>
            <div className="card-body table-responsive">
              <div className="row">
                <div className="col-lg-6">
                  <table className="table table-sm table-bordered table-hover table-head-fixed text-nowrap table-reservation">
                    <thead>
                      <tr>
                        <th>番号</th>
                        <th>認証番号</th>
                        <th>車体番号</th>
                        <th>備考</th>
                      </tr>
                    </thead>
                    <tbody>{table_body_col_1}</tbody>
                  </table>
                </div>
                <div className="col-lg-6">
                  <table className="table table-sm table-bordered table-hover table-head-fixed text-nowrap table-reservation">
                    <thead>
                      <tr>
                        <th>番号</th>
                        <th>認証番号</th>
                        <th>車体番号</th>
                        <th>備考</th>
                      </tr>
                    </thead>
                    <tbody>{table_body_col_2}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        );
        // タブ
        //console.log("tab");
        let className = "card card-primary tab-pane fade";
        if (j === 1) {
          className = "card card-primary tab-pane fade active show";
        }
        let tab_key = "tab-round-" + j;
        round_html_list[j] = (
          <div
            className={className}
            key={tab_key}
            id={tab_key}
            role="tabpanel"
            aria-labelledby={tab_key}
          >
            <div className="card-body table-responsive">
              <div className="row">
                <div className="col-lg-6">{inspection_1_2_html}</div>
              </div>
            </div>
          </div>
        );
      }
    }

    return (
      <div className="content-wrapper">
        <Toaster />
        {/*<!-- Content Header (Page header) -->*/}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="mb-0">予約管理</h3>
              </div>
            </div>
          </div>
          {/*<!-- /.container-fluid -->*/}
        </section>
        <Modal
          show={this.state.create_modal_show}
          title="予約受付"
          body={
            <>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>予約日</label>
                    <span className="form-text">
                      {this.state.create_reservation.reservation_date}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>検査場</label>
                    <span className="form-text">
                      {this.state.create_reservation.site_name}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>自動車区分</label>
                    <span className="form-text">
                      {this.state.create_reservation.car_type_name}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>ラウンド</label>
                    <span className="form-text">
                      {this.state.create_reservation.round_name}
                    </span>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>検査種別</label>
                    <span className="form-text">
                      {this.state.create_reservation.inspection_type_name}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>予約番号</label>
                    <span className="form-text">
                      {this.state.create_reservation.reservation_no}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>認証番号</label>
                    <input
                      type="text"
                      className="form-control"
                      maxLength="4"
                      value={this.state.create_reservation.auth_no}
                      onChange={this.handleCreateAuthNo}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>車体番号</label>
                    <input
                      type="text"
                      className="form-control"
                      maxLength="4"
                      value={this.state.create_reservation.body_no}
                      onChange={this.handleCreateBodyNo}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>ナンバープレート</label>
                    <input
                      type="text"
                      className="form-control"
                      maxLength="4"
                      value={this.state.create_reservation.plate_no}
                      onChange={this.handleCreatePlateNo}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>備考</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.create_reservation.remark}
                      onChange={this.handleCreateRemark}
                    />
                  </div>
                </div>
              </div>
            </>
          }
          footer={
            <>
              <Button
                variant="primary"
                className="btn btn-primary mr-auto"
                onClick={this.createOnClick}
              >
                登録
              </Button>
            </>
          }
          onHide={this.handleCreateModalHide}
        />

        <Modal
          show={this.state.update_modal_show}
          title="予約確認"
          body={
            <>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>予約ID</label>
                    <span className="form-text">
                      {this.state.update_reservation.Reservation.id}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>予約日</label>
                    <span className="form-text">
                      {
                        this.state.update_reservation.Reservation
                          .reservation_date
                      }
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>検査場</label>
                    <span className="form-text">
                      {this.state.update_reservation.site_name}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>自動車区分</label>
                    <span className="form-text">
                      {this.state.update_reservation.car_type_name}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>ラウンド</label>
                    <span className="form-text">
                      {this.state.update_reservation.round_name}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>検査種別</label>
                    <span className="form-text">
                      {this.state.update_reservation.inspection_type_name}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>予約番号</label>
                    <span className="form-text">
                      {this.state.update_reservation.Reservation.reservation_no}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>予約受付</label>
                    <span className="form-text">
                      {this.state.update_reservation.reception_type_name}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>認証番号</label>
                    <span className="form-text">
                      {this.state.update_reservation.auth_no}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>会員名</label>
                    <span className="form-text">
                      {this.state.update_reservation.member_name}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>車体番号</label>
                    <input
                      type="text"
                      className="form-control"
                      maxLength="4"
                      value={this.state.update_reservation.Reservation.body_no}
                      onChange={this.handleUpdateBodyNo}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>ナンバープレート</label>
                    <input
                      type="text"
                      className="form-control"
                      maxLength="4"
                      value={this.state.update_reservation.Reservation.plate_no}
                      onChange={this.handleUpdatePlateNo}
                    />
                  </div>
                </div>
              </div>
              {/*
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>車体番号</label>
                    <span className="form-text">
                      {this.state.update_reservation.Reservation.body_no}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>ナンバープレート</label>
                    <span className="form-text">
                      {this.state.update_reservation.Reservation.plate_no}
                    </span>
                  </div>
                </div>
              </div>
              */}
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>ボイコット</label>
                    <div
                      className="icheck-primary"
                      value={this.state.update_reservation.Reservation.boycott}
                    >
                      <input
                        type="checkbox"
                        className="form-control"
                        id="boycott"
                        name="boycott"
                        value="1"
                        checked={
                          this.state.update_reservation.Reservation.boycott
                            ? true
                            : false
                        }
                        onChange={this.handleUpdateBoycott}
                      />
                      <label for="boycott"> ボイコット</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>備考</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.update_reservation.Reservation.remark}
                      onChange={this.handleUpdateRemark}
                    />
                  </div>
                </div>
              </div>
            </>
          }
          footer={
            <>
              <Button
                variant="primary"
                className="btn btn-primary mr-auto"
                onClick={this.updateOnClick}
              >
                登録
              </Button>
              <Button
                className="btn btn-danger"
                onClick={this.deleteReservation}
              >
                削除
              </Button>
            </>
          }
          onHide={this.handleUpdateModalHide}
        />

        <Modal
          show={this.state.mail_modal_show}
          title="予約状況表メール配信"
          body={
            <>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>予約日</label>
                    <span className="form-text">
                      {this.getFormatDate(this.state.reservation_date)}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>検査場</label>
                    <span className="form-text">
                      {Constant.SITE_NAME_LIST[this.state.site_id]}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>全リスト</label>
                    <div className="icheck-primary" value={this.state.all_f}>
                      <input
                        type="checkbox"
                        className="form-control"
                        id="all_f"
                        name="all_f"
                        value="1"
                        checked={this.state.all_f ? true : false}
                        onChange={this.handleMailAllF}
                      />
                      <label for="all_f"> 全リスト</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="form-group"
                    style={{ wordBreak: "break-word" }}
                  >
                    {this.state.site.mail_to_normal}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>軽のみ</label>
                    <div className="icheck-primary" value={this.state.light_f}>
                      <input
                        type="checkbox"
                        className="form-control"
                        id="light_f"
                        name="light_f"
                        value="1"
                        checked={this.state.light_f ? true : false}
                        onChange={this.handleMailLightF}
                      />
                      <label for="light_f"> 軽のみ</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="form-group"
                    style={{ wordBreak: "break-word" }}
                  >
                    {this.state.site.mail_to_light}
                  </div>
                </div>
              </div>
            </>
          }
          footer={
            <>
              <Button
                variant="info"
                className="btn btn-info mr-auto"
                onClick={this.mailOnClick}
              >
                配信
              </Button>
            </>
          }
          onHide={this.handleMailModalHide}
        />

        {/*<!-- Main content -->*/}
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="card-body pt-2 pb-2">
                <div className="row">
                  <div className="col-lg-2" style={{ maxWidth: "160px" }}>
                    <label className="small">予約日</label>
                    <div className="form-group mb-0">
                      <div
                        className="input-group date reservation_date"
                        id="reservation_date"
                        data-target-input="nearest"
                      >
                        <DatePicker
                          className="form-control"
                          selected={this.state.reservation_date}
                          onChange={(date) => this.handleReservationDate(date)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2" style={{ maxWidth: "200px" }}>
                    <label className="small">検査場</label>
                    <div className="form-group mb-0">
                      <select
                        className="form-control select2bs4"
                        style={{ width: "100%" }}
                        value={this.state.site_id}
                        onChange={this.handleSiteId}
                      >
                        <option value="1">沖縄本所</option>
                        <option value="2">宮古支所</option>
                        <option value="3">八重山支所</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-3" style={{ maxWidth: "240px" }}>
                    <label className="small">自動車区分</label>
                    <div className="form-group mb-0">
                      <div
                        className="form-check-inline"
                        value={this.state.car_type}
                        onChange={this.handleCarType}
                      >
                        <div className="icheck-primary">
                          <input
                            type="radio"
                            className="form-control"
                            id="car_type_normal"
                            name="car_type"
                            defaultChecked
                            value="1"
                          />
                          <label htmlFor="car_type_normal">普通自動車</label>
                        </div>
                        <div className="icheck-primary ml-2">
                          <input
                            type="radio"
                            className="form-control"
                            id="car_type_small"
                            name="car_type"
                            value="2"
                          />
                          <label htmlFor="car_type_small">軽自動車</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer pt-2 pb-2">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group clearfix mb-0">
                      {/*
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={this.searchOnClick}
                      >
                        検索
                      </button>
                      &nbsp;
                      */}
                      <button
                        type="button"
                        className="btn btn-info"
                        data-toggle="modal"
                        //data-target="#modal-reservation-mail"
                        onClick={this.handleMailModalShow}
                      >
                        メール配信
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={this.csvOnClick}
                      >
                        CSV出力
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={this.pdfOnClick}
                      >
                        PDF出力
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-primary card-outline card-outline-tabs mb-0">
                  <div className="card-header p-0 border-bottom-0">
                    <ul className="nav nav-tabs nav-justified" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-toggle="pill"
                          href="#tab-round-1"
                          role="tab"
                          aria-controls="tab-round-1"
                          aria-selected="true"
                        >
                          1R
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-toggle="pill"
                          href="#tab-round-2"
                          role="tab"
                          aria-controls="tab-round-2"
                          aria-selected="true"
                        >
                          2R
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-toggle="pill"
                          href="#tab-round-3"
                          role="tab"
                          aria-controls="tab-round-3"
                          aria-selected="true"
                        >
                          3R
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-toggle="pill"
                          href="#tab-round-4"
                          role="tab"
                          aria-controls="tab-round-4"
                          aria-selected="false"
                        >
                          4R
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-toggle="pill"
                          href="#tab-round-5"
                          role="tab"
                          aria-controls="tab-round-5"
                          aria-selected="true"
                        >
                          5R
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 tab-content">
                {round_html_list[1]}
                {round_html_list[2]}
                {round_html_list[3]}
                {round_html_list[4]}
                {round_html_list[5]}
              </div>
            </div>
            <div className="modal fade" id="modal-reservation-mail">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header bg-primary">
                    <h4 className="modal-title">予約メール配信</h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>予約日</label>
                          <span className="form-text">2022/07/03</span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>検査場</label>
                          <span className="form-text">沖縄本所</span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>自動車区分</label>
                          <span className="form-text">普通自動車</span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>配信先メールアドレス</label>
                          <span className="form-text">
                            aaa@example.com,bbb@example.com,ccc@example.com
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-info mr-auto">
                      配信
                    </button>
                  </div>
                </div>
                {/*<!-- /.modal-content -->*/}
              </div>
              {/*<!-- /.modal-dialog -->*/}
            </div>
            {/*<!-- /.modal -->*/}
          </div>
          {/*<!-- /.container-fluid -->*/}
        </section>
        {/*<!-- /.content -->*/}
      </div>
    );
  }
}
