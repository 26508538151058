import { API, Auth } from 'aws-amplify';
import React from 'react';
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import config from "../config.json";
import { DatePicker } from "./DatePicker";
import './datepicker.css';
import NewsEditBase from "./NewsEditBase.js";
import OverlayLoading from './OverlayLoading.js'

export default function NewsEdit(props) {
  const params = useParams();
  const navigate = useNavigate();
  return (
    <NewsEditComp {...props} navigate={ navigate } params={params} />
  );
}

class NewsEditComp extends React.Component {
  constructor(props, params) {
    super(props);
    this.fileInput = React.createRef();
    this.state = {
      api_name: config.stage,
      loading: false,
      showDeleteButton: true,
      ref_attachment_file: React.createRef(),
      id: props.params.newsId,
      title: '',
      content: '',
      attachment: '',
      publish_date_start: '',
      publish_date_end: '',
    };

  }

  componentDidMount() {
    this.getNews();
  }

  getToken = async () => {
    return `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
  };

  getNews = async () => {
    this.setState({ loading: true });

    const myinit = {
      headers: {
        Authorization: await this.getToken(),
      },
    };

    await API.get(this.state.api_name, "/news/" + this.state.id, myinit).then((n) => {
        this.setState({
          title: n.title,
          content: n.content,
          attachment: n.attachment,
          publish_date_start: this.getDate(n.publish_date_start),
          publish_date_end: this.getDate(n.publish_date_end),
        });
      }).catch((e) => {
        toast.error("お知らせデータの取得エラー");
      });

    this.setState({ loading: false });
  };
  
  getDateString = (d) => {
    return d ? d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() : '';
  };

  getDate = (s) => {
    if (s == '0000-00-00') {
      return null;
    } else {
      return new Date(s);
    }
  };

  putFile = async () => {
    const myinit = {
      headers: {
        Authorization: await this.getToken(),
      },
      queryStringParameters: {
        filename: this.state.ref_attachment_file.current.files[0].name,
      },
    }
    const { url, filename } = await API.get(this.state.api_name, "/news/upload_url", myinit);
    this.setState({ attachment: filename });
    const formData = new FormData();
    formData.append(filename, this.state.ref_attachment_file.current.files[0]);
    await fetch(url, { method: 'PUT', body: formData });
  };

  updateNews = async () => {
    this.setState({ loading: true });

    if (this.state.ref_attachment_file.current.files[0]) {
      await this.putFile();
    }

    const news = {
      id: this.state.id,
      title: this.state.title,
      content: this.state.content,
      attachment: this.state.attachment,
      publish_date_start: this.getDateString(this.state.publish_date_start),
      publish_date_end: this.getDateString(this.state.publish_date_end),
    }

    const myinit = {
      headers: {
        Authorization: await this.getToken(),
      },
      body: news,
    };

    await API.put(this.state.api_name, "/news/" + this.state.id, myinit).then((r) => {
      toast.success("お知らせを登録しました");
    }).catch((e) => {
      toast.error("お知らせの登録エラー");
    });

    this.setState({ loading: false});
  };

  sleep = (msec) => {
    return new Promise(function(resolve) {
      setTimeout(function() {resolve()}, msec);
    })
  }

  deleteNews = async () => {
    this.setState({ loading: true });

    const myinit = {
      headers: {
        Authorization: await this.getToken(),
      },
    };

    await API.del(this.state.api_name, "/news/" + this.state.id, myinit).then((r) => {
      toast.success("お知らせを削除しました");
    }).catch((e) => {
      toast.error("お知らせデータの削除エラー");
    });

    this.setState({ loading: false });
    this.props.navigate('/news_list');
  };

  getFile = async () => {
    const myinit = {
      headers: {
        Authorization: await this.getToken(),
      },
    };
    await API.get(this.state.api_name, "/news/download/" + this.state.id, myinit).then((r) => {
      if (r) {
          const a = document.createElement("a");
          a.href = r;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
      }
    }).catch((e) => {
      alert("データ取得エラー"); // TODO
    });
  };

  render() {
    const download_attachment = [];
    if (this.state.attachment) {
      download_attachment.push(
        <button
          key="download"
          type="button"
          className="btn btn-info"
          onClick={ this.getFile }
        >
          { this.state.attachment.substring(14) }
        </button>
      );
    } else {
      download_attachment.push(
        <span key="no-download">添付ファイルはありません</span>
      );
    }

    return (
      <>
        <OverlayLoading show={ this.state.loading } />
        <Toaster />
        <NewsEditBase 
          showDeleteButton={ this.state.showDeleteButton }
          onClick={ this.updateNews }
          onClickDelete={ this.deleteNews }
          title_input={
            <input 
              type="text" 
              className="form-control" 
              id="title" 
              placeholder="会員へのお知らせタイトル" 
              value={ this.state.title }
              onChange={ (event) => { this.setState({ title: event.target.value }) }}
            />
          }
          content_input={
            <textarea 
              className="form-control" 
              id="content" 
              placeholder="会員へのお知らせ本文" 
              rows="8" 
              value={ this.state.content }
              onChange={ (event) => { this.setState({ content: event.target.value }) }}
            />
          }
          file_input={
            <input 
              type="file" 
              ref={ this.state.ref_attachment_file }
            />
          }
          download_attachment={ download_attachment }
          publish_date_start={
            <div className="input-group-append" data-target="#publish_date_start" data-toggle="datetimepicker">
              <DatePicker
                className="form-control"
                selected={ this.state.publish_date_start }
                onChange={ (event) => { this.setState({ publish_date_start: event }) }}
                selectStart
                startDate={ this.state.publish_date_start }
                endDate={ this.state.publish_date_end }
              />
              <div className="input-group-text"><i className="fa fa-calendar"></i></div>
            </div>
          }
          publish_date_end={
            <div className="input-group-append" data-target="#publish_date_start" data-toggle="datetimepicker">
              <DatePicker
                className="form-control"
                selected={ this.state.publish_date_end }
                onChange={ (event) => { this.setState({ publish_date_end: event }) }}
                selectEnd
                startDate={ this.state.publish_date_start }
                endDate={ this.state.publish_date_end }
                minDate={ this.state.publish_date_start }
              />
              <div className="input-group-text"><i className="fa fa-calendar"></i></div>
            </div>
          }
        />
      </>
    );
  }
}

