import { API, Auth } from "aws-amplify";
import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Modal from "../components/Modal";
import config from "../config.json";

export default function Holiday(props) {
  const navigate = useNavigate();
  return <HolidayComp {...props} navigate={navigate} />;
}
class HolidayComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_name: config.stage,
      loading: false,
      holidays: [],
      holiday_id: "",
      holiday_mode: 0,
      holiday_label: "",
      holiday_mm: "",
      holiday_dd: "",
      holiday_slide: 0,
      holiday_updated_at: "",
      holiday1_modal_show: false,
      holiday2_modal_show: false,
    };
  }

  componentDidMount() {
    this.getHolidays();
  }

  getToken = async () => {
    return `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
  };

  getHolidays = async () => {
    this.setState({ loading: true });

    const myinit = {
      headers: {
        Authorization: await this.getToken(),
      },
    };

    await API.get(this.state.api_name, "/holidays", myinit)
      .then((r) => {
        this.setState({
          holidays: r,
        });
      })
      .catch((e) => {
        toast.error("休日データの取得エラー");
      });

    this.setState({ loading: false });
  };

  //update or create
  updateHoliday = async () => {
    if (!this.state.holiday_mm) {
      alert("月を選択してください。");
      return;
    }
    if (!this.state.holiday_dd) {
      alert("日を選択してください。");
      return;
    }
    if (!this.state.holiday_label) {
      alert("祝日名／休日名を入力してください。");
      return;
    }

    this.setState({ loading: true });
    const myinit = {
      headers: {
        Authorization: await this.getToken(),
      },
      body: {
        id: this.state.holiday_id,
        mode: this.state.holiday_mode,
        label: this.state.holiday_label,
        mm: this.state.holiday_mm,
        dd: this.state.holiday_dd,
        slide: this.state.holiday_slide,
        holiday_updated_at: this.state.holiday_updated_at,
      },
    };

    if (this.state.holiday_id) {
      //update
      await API.put(
        this.state.api_name,
        "/holidays/" + this.state.holiday_id,
        myinit
      )
        .then((r) => {
          this.getHolidays();
          this.handleHoliday1ModalHide();
          this.handleHoliday2ModalHide();
          toast.success("休日データを登録しました");
        })
        .catch((e) => {
          toast.error("休日データの登録エラー");
        });
    } else {
      //create
      await API.post(this.state.api_name, "/holidays", myinit)
        .then((r) => {
          this.getHolidays();
          this.handleHoliday1ModalHide();
          this.handleHoliday2ModalHide();
          toast.success("休日データを登録しました");
        })
        .catch((e) => {
          toast.error("休日データの登録エラー");
        });
    }

    this.setState({ loading: false });
  };

  deleteHoliday = async () => {
    this.setState({ loading: true });

    const myinit = {
      headers: {
        Authorization: await this.getToken(),
      },
    };

    await API.del(
      this.state.api_name,
      "/holidays/" + this.state.holiday_id,
      myinit
    )
      .then((r) => {
        this.getHolidays();
        this.handleHoliday1ModalHide();
        this.handleHoliday2ModalHide();
        toast.success("休日データを削除しました");
      })
      .catch((e) => {
        toast.error("休日データの削除エラー");
      });

    this.setState({ loading: false });
  };

  zeloPadding = (n) => {
    return String(n).padStart(2, "0");
  };

  handleHoliday1ModalShow = (h) => {
    if (h) {
      this.setState({
        holiday_id: h.id,
        holiday_mode: h.mode,
        holiday_label: h.label,
        holiday_mm: h.mm,
        holiday_dd: h.dd,
        holiday_slide: h.slide,
        holiday_updated_at: h.updated_at,
        holiday1_modal_show: true,
      });
    } else {
      this.setState({
        holiday_id: "",
        holiday_mode: 1,
        holiday_label: "",
        holiday_mm: "",
        holiday_dd: "",
        holiday_slide: 0,
        holiday_updated_at: "",
        holiday1_modal_show: true,
      });
    }
  };

  handleHoliday2ModalShow = (h) => {
    if (h) {
      this.setState({
        holiday_id: h.id,
        holiday_mode: h.mode,
        holiday_label: h.label,
        holiday_mm: h.mm,
        holiday_dd: h.dd,
        holiday_slide: h.slide,
        holiday_updated_at: h.updated_at,
        holiday2_modal_show: true,
      });
    } else {
      this.setState({
        holiday_id: "",
        holiday_mode: 2,
        holiday_label: "",
        holiday_mm: "",
        holiday_dd: "",
        holiday_slide: 0,
        holiday_updated_at: "",
        holiday2_modal_show: true,
      });
    }
  };

  handleHoliday1ModalHide = () => {
    this.setState({
      holiday1_modal_show: false,
    });
  };

  handleHoliday2ModalHide = () => {
    this.setState({
      holiday2_modal_show: false,
    });
  };

  render() {
    const holidays_mode1_html = [];
    const holidays_mode2_html = [];
    const h = this.state.holidays;
    for (let i = 0; i < h.length; i++) {
      if (parseInt(h[i].mode) == 1) {
        holidays_mode1_html.push(
          <tr key={h[i].id}>
            <td style={{ width: "75px", textAlign: "center" }}>
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={() => this.handleHoliday1ModalShow(h[i])}
              >
                編集
              </button>
            </td>
            <td>
              {this.zeloPadding(h[i].mm)}/{this.zeloPadding(h[i].dd)}
            </td>
            <td>{h[i].label}</td>
          </tr>
        );
      } else if (parseInt(h[i].mode) == 2) {
        holidays_mode2_html.push(
          <tr key={h[i].id}>
            <td style={{ width: "75px", textAlign: "center" }}>
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={() => this.handleHoliday2ModalShow(h[i])}
              >
                編集
              </button>
            </td>
            <td>
              {this.zeloPadding(h[i].mm)}/{this.zeloPadding(h[i].dd)}
            </td>
            <td>{h[i].label}</td>
            <td>{h[i].slide === 1 ? "✔" : ""}</td>
            <td>{h[i].slide === 2 ? "✔" : ""}</td>
          </tr>
        );
      }
    }

    const option_dd_html = [];
    for (let i = 1; i < 32; i++) {
      option_dd_html.push(
        <option key={i} value={i}>
          {this.zeloPadding(i)}
        </option>
      );
    }

    const option_mm_html = [];
    for (let i = 1; i < 13; i++) {
      option_mm_html.push(
        <option key={i} value={i}>
          {this.zeloPadding(i)}
        </option>
      );
    }

    return (
      <div className="content-wrapper">
        <Toaster />
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="mb-0">休日マスター</h3>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <div className="card card-primary card-outline">
                  <div className="card-header">
                    <h6 style={{ lineHeight: "1.8" }}>祝日設定</h6>
                    <button
                      type="button"
                      className="btn btn-sm btn-primary ml-2"
                      onClick={() => this.handleHoliday1ModalShow()}
                    >
                      ＋
                    </button>
                  </div>
                  <div className="card-body table-responsive">
                    <table
                      id="holiday_datatable_1"
                      className="table table-sm table-bordered table-hover table-head-fixed text-nowrap"
                    >
                      <thead>
                        <tr>
                          <th>編集</th>
                          <th>月日</th>
                          <th>祝日名</th>
                        </tr>
                      </thead>
                      <tbody>{holidays_mode1_html}</tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card card-primary card-outline">
                  <div className="card-header">
                    <h6 style={{ lineHeight: "1.8" }}>休日設定</h6>
                    <button
                      type="button"
                      className="btn btn-sm btn-primary ml-2"
                      onClick={() => this.handleHoliday2ModalShow()}
                    >
                      ＋
                    </button>
                  </div>
                  <div className="card-body table-responsive">
                    <table
                      id="holiday_datatable_2"
                      className="table table-sm table-bordered table-hover table-head-fixed text-nowrap"
                    >
                      <thead>
                        <tr>
                          <th>編集</th>
                          <th>月日</th>
                          <th>休日名</th>
                          <th>
                            日曜
                            <br />
                            スライド
                          </th>
                          <th>
                            土曜
                            <br />
                            スライド
                          </th>
                        </tr>
                      </thead>
                      <tbody>{holidays_mode2_html}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              show={this.state.holiday1_modal_show}
              title="祝日設定"
              onHide={this.handleHoliday1ModalHide}
              body={
                <>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>月日</label>
                        <div className="form-inline">
                          <select
                            className="form-control select2bs4"
                            style={{ width: "20%" }}
                            value={this.state.holiday_mm}
                            onChange={(event) => {
                              this.setState({
                                holiday_mm: parseInt(event.target.value),
                              });
                            }}
                          >
                            <option>-</option>
                            {option_mm_html}
                          </select>
                          <span className="ml-2 mr-2">/</span>
                          <select
                            className="form-control select2bs4"
                            style={{ width: "20%" }}
                            value={this.state.holiday_dd}
                            onChange={(event) => {
                              this.setState({
                                holiday_dd: parseInt(event.target.value),
                              });
                            }}
                          >
                            <option>-</option>
                            {option_dd_html}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>祝日名</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.holiday_label}
                          onChange={(event) => {
                            this.setState({
                              holiday_label: event.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/*
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>最終更新日時</label>
                        <span className="form-text">
                          {this.state.holiday_updated_at}
                        </span>
                      </div>
                    </div>
                  </div>
                  */}
                  {/*
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>最終更新者</label>
                        <span className="form-text">{ this.state.holiday.updated_user_id }</span>
                      </div>
                    </div>
                  </div>
                  */}
                </>
              }
              footer={
                <>
                  <button
                    type="button"
                    className="btn btn-primary mr-auto"
                    onClick={this.updateHoliday}
                  >
                    登録
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={this.deleteHoliday}
                  >
                    削除
                  </button>
                </>
              }
            />
            <Modal
              show={this.state.holiday2_modal_show}
              title="休日設定"
              onHide={this.handleHoliday2ModalHide}
              body={
                <>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>月日</label>
                        <div className="form-inline">
                          <select
                            className="form-control select2bs4"
                            style={{ width: "20%" }}
                            value={this.state.holiday_mm}
                            onChange={(event) => {
                              this.setState({
                                holiday_mm: parseInt(event.target.value),
                              });
                            }}
                          >
                            <option>-</option>
                            {option_mm_html}
                          </select>
                          <span className="ml-2 mr-2">/</span>
                          <select
                            className="form-control select2bs4"
                            style={{ width: "20%" }}
                            value={this.state.holiday_dd}
                            onChange={(event) => {
                              this.setState({
                                holiday_dd: parseInt(event.target.value),
                              });
                            }}
                          >
                            <option>-</option>
                            {option_dd_html}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>休日名</label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.holiday_label}
                          onChange={(event) => {
                            this.setState({
                              holiday_label: event.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>スライド</label>
                        <br />
                        <div className="form-check-inline">
                          <div className="icheck-primary">
                            <input
                              type="radio"
                              id="slide_sun"
                              className="form-control"
                              name="slide"
                              value="1"
                              checked={this.state.holiday_slide === 1}
                              onChange={(event) => {
                                this.setState({
                                  holiday_slide: parseInt(event.target.value),
                                });
                              }}
                            />
                            <label htmlFor="slide_sun">日曜スライド　</label>
                          </div>
                          <div className="icheck-primary">
                            <input
                              type="radio"
                              id="slide_sat"
                              className="form-control"
                              name="slide"
                              value="2"
                              checked={this.state.holiday_slide === 2}
                              onChange={(event) => {
                                this.setState({
                                  holiday_slide: parseInt(event.target.value),
                                });
                              }}
                            />
                            <label htmlFor="slide_sat">土曜スライド　</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>最終更新日時</label>
                        <span className="form-text">
                          {this.state.holiday_updated_at}
                        </span>
                      </div>
                    </div>
                  </div>
                  */}
                  {/*
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>最終更新者</label>
                        <span className="form-text">{ this.state.holiday.updated_user_id }</span>
                      </div>
                    </div>
                  </div>
                  */}
                </>
              }
              footer={
                <>
                  <button
                    type="button"
                    className="btn btn-primary mr-auto"
                    onClick={this.updateHoliday}
                  >
                    登録
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={this.deleteHoliday}
                  >
                    削除
                  </button>
                </>
              }
            />
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
        {/* /.content-wrapper */}
      </div>
    );
  }
}
