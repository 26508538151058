import { API, Auth } from "aws-amplify";
import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import config from "../config.json";

export default function SiteList(props) {
  const navigate = useNavigate();
  return <SiteListComp {...props} navigate={navigate} />;
}
class SiteListComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_name: config.stage,
      email: "",
      sites: [],
    };
  }

  componentDidMount() {
    this.getSites();
  }

  getToken = async () => {
    return `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
  };

  getSites = async () => {
    const myinit = {
      headers: {
        Authorization: await this.getToken(),
      },
      queryStringParameters: {
        email: this.state.email,
      },
    };

    await API.get(this.state.api_name, "/sites", myinit)
      .then((r) => {
        this.setState({
          myinit: myinit,
          sites: r,
        });
      })
      .catch((e) => {
        toast.error("検査場データの取得エラー");
      });
  };

  handleEmail = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  searchOnClick = async (event) => {
    this.getSites();
  };

  render() {
    const sites_html = [];
    const fs = this.state.sites;
    for (let i = 0; i < fs.length; i++) {
      sites_html.push(
        <tr key={i}>
          <td style={{ width: "75px", textAlign: "center" }}>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => {
                this.props.navigate("/site_edit/" + fs[i].id);
              }}
            >
              編集
            </button>
          </td>
          <td>{fs[i].name}</td>
          <td style={{ width: "40%", wordBreak: "break-word" }}>
            {fs[i].mail_to_normal}
          </td>
          <td style={{ width: "40%", wordBreak: "break-word" }}>
            {fs[i].mail_to_light}
          </td>
        </tr>
      );
    }

    return (
      <div className="content-wrapper">
        {/* Content Wrapper. Contains page content */}
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="mb-0">検査場マスター</h3>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="card-body pt-2 pb-2">
                <div className="row">
                  <div className="col-lg-2" style={{ maxWidth: "250px" }}>
                    <label className="small">配信先メールアドレス</label>
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder="※部分一致"
                        value={this.state.email}
                        onChange={this.handleEmail}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer pt-2 pb-2">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group clearfix mb-0">
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={this.searchOnClick}
                      >
                        検索
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card card-primary card-outline">
              <div className="card-body table-responsive">
                <table
                  id="site_datatable"
                  className="table table-sm table-bordered table-hover table-head-fixed"
                >
                  <thead>
                    <tr>
                      <th>編集</th>
                      <th>検査場名</th>
                      <th>配信先メールアドレス（普通）</th>
                      <th>配信先メールアドレス（軽）</th>
                    </tr>
                  </thead>
                  <tbody>{sites_html}</tbody>
                </table>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
        {/* /.content-wrapper */}
      </div>
    );
  }
}
