import { API, Auth } from "aws-amplify";
import React from "react";
import toast from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import config from "../config.json";

export default function UserList(props) {
  const navigate = useNavigate();
  return <UserListComp {...props} navigate={navigate} />;
}

class UserListComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_name: config.stage,
      loading: false,
      users: [],
    };
  }

  componentDidMount() {
    this.getUsers();
  }

  getToken = async () => {
    return `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
  };

  getUsers = async () => {
    this.setState({ loading: true });
    const myinit = {
      headers: {
        Authorization: await this.getToken(),
      },
      queryStringParameters: {
        name: this.state.name,
        email: this.state.email,
      },
    };
    await API.get(this.state.api_name, "/users", myinit)
      .then((r) => {
        this.setState({
          users: r,
        });
      })
      .catch((e) => {
        toast.error("担当者データの取得エラー");
      });
    this.setState({ loading: false });
  };

  handleName = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  handleEmail = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  searchOnClick = async (event) => {
    this.getUsers();
  };

  render() {
    const users_html = [];
    const status_list = {
      0: "無効",
      1: "有効",
    };
    const u = this.state.users;
    for (let i = 0; i < u.length; i++) {
      users_html.push(
        <tr key={i}>
          <td style={{ width: "75px", textAlign: "center" }}>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => {
                this.props.navigate("/user_edit/" + u[i].id);
              }}
            >
              編集
            </button>
          </td>
          <td>{u[i].name}</td>
          <td>{u[i].kana}</td>
          <td>{u[i].email}</td>
          <td>{status_list[u[i].status]}</td>
        </tr>
      );
    }

    return (
      <div className="content-wrapper">
        {/* Content Wrapper. Contains page content */}
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="mb-0">担当者マスター</h3>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="card-body pt-2 pb-2">
                <div className="row">
                  <div className="col-lg-2" style={{ maxWidth: "250px" }}>
                    <label className="small">担当者名</label>
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="※部分一致"
                        value={this.state.name}
                        onChange={this.handleName}
                      />
                    </div>
                  </div>
                  <div className="col-lg-2" style={{ maxWidth: "250px" }}>
                    <label className="small">メールアドレス</label>
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder="※部分一致"
                        value={this.state.email}
                        onChange={this.handleEmail}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer pt-2 pb-2">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group clearfix mb-0">
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={this.searchOnClick}
                      >
                        検索
                      </button>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group clearfix mb-0 text-right">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          this.props.navigate("/user_edit");
                        }}
                      >
                        新規登録
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card card-primary card-outline">
              <div className="card-body table-responsive">
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#4fa94d"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={this.state.loading}
                />
                <table
                  id="user_datatable"
                  className="table table-sm table-bordered table-hover table-head-fixed text-nowrap"
                >
                  <thead>
                    <tr>
                      <th>編集</th>
                      <th>担当者名</th>
                      <th>担当者カナ</th>
                      <th>メールアドレス</th>
                      <th>ステータス</th>
                    </tr>
                  </thead>
                  <tbody>{users_html}</tbody>
                </table>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
        {/* /.content-wrapper */}
      </div>
    );
  }
}
