import { API, Auth } from 'aws-amplify';
import React from 'react';
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import config from "../config.json";
import { DatePicker } from "./DatePicker";
import './datepicker.css';
import NewsEditBase from "./NewsEditBase.js";
import OverlayLoading from './OverlayLoading.js'

export default function NewsNew(props) {
  const navigate = useNavigate();

  return (
    <NewsNewComp {...props} navigate={ navigate } />
  );
}

class NewsNewComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_name: config.stage,
      loading: false,
      showDeleteButton: false,
      ref_attachment_file: React.createRef(),
      filename: '',
      title: '',
      content: '',
      attachment: '',
      publish_date_start: '',
      publish_date_end: '',
    };
  }

  getToken = async () => {
    return `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
  };

  getDateString = (d) => {
    return d ? d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() : '';
  };

  createNews = async () => {
    this.setState({ loading: true });

    if (this.state.ref_attachment_file.current.files[0]) {
      await this.putFile();
    }

    const news = {
      title: this.state.title,
      content: this.state.content,
      attachment: this.state.attachment,
      publish_date_start: this.getDateString(this.state.publish_date_start),
      publish_date_end: this.getDateString(this.state.publish_date_end),
    }

    const myinit = {
      headers: {
        Authorization: await this.getToken(),
      },
      body: news,
    };

    const n = await API.post(this.state.api_name, "/news", myinit).catch(() => null);

    this.setState({ loading: false });

    if (n) {
      toast.success("お知らせを登録しました");
      await this.props.navigate('/news_edit/' + n.id);
    } else {
      toast.error("お知らせの登録エラー");
    }

  };

  putFile = async () => {
    const myinit = {
      headers: {
        Authorization: await this.getToken(),
      },
      queryStringParameters: {
        filename: this.state.ref_attachment_file.current.files[0].name,
      },
    }
    const { url, filename } = await API.get(this.state.api_name, "/news/upload_url", myinit);
    this.setState({ attachment: filename });
    const formData = new FormData();
    formData.append(filename, this.state.ref_attachment_file.current.files[0]);
    await fetch(url, { method: 'PUT', body: formData });
  };

  render() {
    return (
      <>
        <OverlayLoading show={ this.state.loading } />
        <Toaster />
        <NewsEditBase 
          showDeleteButton={ this.state.showDeleteButton }
          onClick={ this.createNews }
          title_input={
            <input 
              type="text" 
              className="form-control" 
              id="title" 
              placeholder="会員へのお知らせタイトル" 
              value={ this.state.title }
              onChange={ (event) => { this.setState({ title: event.target.value }) }}
            />
          }
          content_input={
            <textarea 
              className="form-control" 
              id="content" 
              placeholder="会員へのお知らせ本文" 
              rows="8" 
              value={ this.state.content }
              onChange={ (event) => { this.setState({ content: event.target.value }) }}
            />
          }
          file_input={
            <input 
              type="file" 
              ref={ this.state.ref_attachment_file }
            />
          }
          publish_date_start={
            <div className="input-group-append" data-target="#publish_date_start" data-toggle="datetimepicker">
              <DatePicker
                className="form-control"
                selected={ this.state.publish_date_start }
                onChange={ (event) => { this.setState({ publish_date_start: event }) }}
                selectStart
                startDate={ this.state.publish_date_start }
                endDate={ this.state.publish_date_end }
              />
              <div className="input-group-text"><i className="fa fa-calendar"></i></div>
            </div>
          }
          publish_date_end={
            <div className="input-group-append" data-target="#publish_date_start" data-toggle="datetimepicker">
              <DatePicker
                className="form-control"
                selected={ this.state.publish_date_end }
                onChange={ (event) => { this.setState({ publish_date_end: event }) }}
                selectEnd
                startDate={ this.state.publish_date_start }
                endDate={ this.state.publish_date_end }
                minDate={ this.state.publish_date_start }
              />
              <div className="input-group-text"><i className="fa fa-calendar"></i></div>
            </div>
          }
        />
      </>
    );
  }
}

