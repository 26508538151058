import { ThreeDots } from "react-loader-spinner";

export default function OverlayLoading(props) {
  const { show } = props;
  const styles = {
    position: "absolute",
    left: "0",
    top: "0",
    width: "100vw",
    height: "100vh",
    background: "rgba(100, 100, 100, .8)",
    zIndex: "2147483647",
    padding: "auto",
  }
  return (
    show && (
      <div
        style={ styles }
      >
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#4fa94d"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
          wrapperClassName=""
          visible={true}
        />
      </div>
    )
  );
}
