import { Authenticator } from "@aws-amplify/ui-react";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CourseEdit from "./components/CourseEdit";
import CourseList from "./components/CourseList";
import CsvOutput from "./components/CsvOutput";
import Dashboard from "./components/Dashboard";
import Holiday from "./components/Holiday";
import MemberEdit from "./components/MemberEdit";
import MemberList from "./components/MemberList";
import NewsList from "./components/NewsList";
import NewsEdit from "./components/NewsEdit";
import NewsNew from "./components/NewsNew";
import Reservation from "./components/Reservation";
import ReservationLog from "./components/ReservationLog";
import SiteEdit from "./components/SiteEdit";
import SiteList from "./components/SiteList";
import SystemSetting from "./components/SystemSetting";
import UserEdit from "./components/UserEdit";
import UserList from "./components/UserList";

import Layout from "./components/Layout";
import Login from "./components/Login";
import RequireAuth from "./RequireAuth";

import ReactLoading from "react-loading";

function MyRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="reservation" element={<Reservation />} />
          <Route path="reservation_log" element={<ReservationLog />} />
          <Route path="csv_output" element={<CsvOutput />} />
          <Route path="news_list" element={<NewsList />} />
          <Route path="news_edit/:newsId" element={<NewsEdit />} />
          <Route path="news_edit/new" element={<NewsNew />} />
          <Route path="site_list" element={<SiteList />} />
          <Route path="site_edit/:siteId" element={<SiteEdit />} />
          <Route path="member_list" element={<MemberList />} />
          <Route path="member_edit" element={<MemberEdit />} />
          <Route path="member_edit/:memberId" element={<MemberEdit />} />
          <Route path="course_list" element={<CourseList />} />
          <Route path="course_edit" element={<CourseEdit />} />
          <Route path="course_edit/:courseId" element={<CourseEdit />} />
          <Route path="holiday" element={<Holiday />} />
          <Route path="user_list" element={<UserList />} />
          <Route path="user_edit" element={<UserEdit />} />
          <Route path="user_edit/:userId" element={<UserEdit />} />
          <Route path="system_setting" element={<SystemSetting />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  const [loading, setLoading] = React.useState(false);
  const handleLoading = (flag) => {
    setLoading(flag);
  };
  if (loading) {
    return (
      <section className="flex justify-center items-center h-screen">
        <div>
          <ReactLoading
            type="spin"
            color="#ebc634"
            height="100px"
            width="100px"
            className="mx-auto"
          />
          <p className="text-center mt-3">ローディング中</p>
        </div>
      </section>
    );
  } else {
    return (
      <Authenticator.Provider>
        <MyRoutes setLoading={handleLoading} />
      </Authenticator.Provider>
    );
  }
}

export default App;
