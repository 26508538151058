import React from 'react';
import { useNavigate } from "react-router-dom";

export default function NewsEditBase(props) {
  const navigate = useNavigate();
  const { showDeleteButton, onClick, onClickDelete, title_input, 
           content_input, file_input, download_attachment,
           publish_date_start, publish_date_end  } = props;

  const delete_button_html = [];
  if (showDeleteButton) {
    delete_button_html.push(
      <div key="delete" className="col-6 text-right">
        <div className="form-group clearfix mb-0">
          <button 
            type="button" 
            className="btn btn-danger"
            onClick={ onClickDelete }
          >
            削除
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="content-wrapper">
    {/* Content Wrapper. Contains page content */}
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h3 className="mb-0">お知らせ管理</h3>
            </div>
          </div>
        </div>{/* /.container-fluid */}
      </section>

      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          <div className="card card-default">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-1">
                  <label htmlFor="title">タイトル</label>
                </div>
                <div className="col-lg-11">
                  <div className="form-group">
                    { title_input }
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-1">
                  <label htmlFor="content">本文</label>
                </div>
                <div className="col-lg-11">
                  <div className="form-group">
                    { content_input }
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2">
                  <label htmlFor="tmp_file">添付ファイル</label>
                </div>
                <div className="col-lg-10">
                  <div className="form-group">
                    <div className="custom-file">
                      { file_input }
                      { download_attachment }
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-1">
                  <label htmlFor="publish_start_date">公開日</label>
                </div>
                <div className="col-lg-2">
                  <div className="form-group">
                    <div className="input-group date publish_date" id="publish_start_date" data-target-input="nearest">
                      { publish_date_start }
                    </div>
                  </div>
                </div>
                -
                <div className="col-lg-2">
                  <div className="form-group">
                    <div className="input-group date publish_date" id="publish_end_date" data-target-input="nearest">
                      { publish_date_end }
                    </div>
                  </div>
                </div>
              </div>
              {/*
              <div className="row">
                <div className="col-lg-1">
                  <label htmlFor="">最終更新日時</label>
                </div>
                <div className="col-lg-2">
                  2022/01/10 10:10:10
                </div>
                <div className="col-lg-1">
                  <label htmlFor="">最終更新者</label>
                </div>
                <div className="col-lg-2">
                  担当者A
                </div>
              </div>
              */}
            </div>
            <div className="card-footer pt-2 pb-2">
              <div className="row">
                <div className="col-6">
                  <div className="form-group clearfix mb-0">
                    <button 
                      type="button" 
                      className="btn btn-primary"
                      onClick={ onClick }
                    >
                      登録
                    </button>
                    &nbsp;
                    <button
                      type="button" 
                      className="btn btn-default" 
                      onClick={() => {
                        navigate("/news_list");
                      }}
                    >
                      戻る
                    </button>
                  </div>
                </div>
                { delete_button_html }
              </div>
            </div>
          </div>
        </div>{/* /.container-fluid */}
      </section>
      {/* /.content */}

    {/* /.content-wrapper */}
    </div>


  );
}

